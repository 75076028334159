body {
    background-color: white;
}

.navbar {
    background-color: #f6f6f9;
}

.navbar-light .navbar-nav .nav-link {
    color: black;
    font-weight: 500;
    font-size: 14px;
}

.btn-primary {
    color: white;
    background-color: #3c3277;
    border-color: #3c3277;
    border-radius: 1rem;
    width: 160px;
}

.btn-primary-heading {
    color: white;
    background-color: #3c3277;
    border-color: #3c3277;
    border-radius: 1rem;
    width: 150px;
}

.btn-primary:hover, .btn-primary:focus {
    color: white;
    background-color: #ff7b01;
    border-color: #ff7b01;
}

.btn-primary-heading:hover, .btn-primary-heading:focus {
    color: white;
    background-color: #ff7b01;
    border-color: #ff7b01;
}

.btn-secondary {
    color: white !important;
    background-color: #ff7b01;
    border-color: #ff7b01;
    border-radius: 1rem;
}

.btn-secondary:hover {
    color: white;
    background-color: #3c3277;
    border-color: #3c3277;
    border-radius: 1rem;
}


.container-footer {
    background-color: #3c3277;
    color: white;
}

.bottom-nav {

}

.bottom-nav .nav-link {
    color: white;
    font-weight: 700;
    font-size: 16px;
}

.bottom-nav .nav-link:hover {
    color: white;
    font-weight: 500;
}

.contact-details {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.udao-copyright {
    font-weight: normal;
    font-size: 11px;
}


.landing-card {
    border-radius: 30px;
    background-color: #f6f6f9;
    border: none;
}

.landing-card .card-body .card-title {
    font-size: 24px;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 20px;
}

.variant-card .card-body .card-title {
    font-size: 18px;
    font-weight: bolder;
    margin-top: 15px;
    margin-bottom: 20px;
}

.package-card .card-body .card-title {
    font-size: 24px;
    font-weight: bolder;
    margin-top: 15px;
    margin-bottom: 30px;
}

.package-card .card-body .card-subtitle {
    font-size: 16px;
    font-weight: bold;
    color: #ff7b01;
    margin-bottom: 20px;
}

.package-card .card-body ul .active {
    font-size: 14px;
    font-weight: bolder;
    color: black;
    list-style-image: url('/images/vector.svg');
    list-style-type: none;
    list-style-position: inside;
}

.package-card .card-body ul .inactive {
    font-size: 14px;
    font-weight: bolder;
    color: gray;
    list-style-image: none;
    list-style-type: none;
}

.package-card .card-body .card-subtitle .small {
    font-size: 12px;
}

.package-card .card-body .card-subtitle .black {
    color: black;
    font-size: 12px;
}

.landing-card .card-body .card-subtitle {
    margin-bottom: 25px;
    font-weight: 500;
    font-size: 14px;
}

.landing-card .card-body .card-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.gray-rounded-box {
    background-color: #f6f6f9;
    border-radius: 30px;
}

.orange-rounded-box {
    background-color: #f6f6f9;
    border-radius: 30px;
    border-color: #ff7b01;
}

.orange-rounded-box-white {
    background-color: white;
    border-radius: 30px;
    border-color: #ff7b01;
}

.gray-rounded-box-padding {
    padding-left: 100px;
    padding-right: 100px;
}

.udao-header {
    font-size: 48px;
    font-weight: 800;
    line-height: 60px;
}

.udao-header-smaller {
    font-size: 32px;
    font-weight: bolder;
    line-height: 50px;
}

.udao-header i {
    font-size: 48px;
    font-weight: normal;
}

.udao-header-smaller i {
    font-size: 32px;
    font-weight: normal;
}

.container-komfort {
    background-image: url('/images/bg_1.svg');
    background-position: top center;
    background-repeat: no-repeat;
}

.udao-komfort {
    color: white;
}

.udao-komfort-heading {
    color: white;
    font-weight: 700;
    font-size: 24px;
}

.udao-komfort-text {
    color: white;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}

.container-icons {
    background-image: url('/images/bg_2.svg');
    background-position: top center;
    background-repeat: no-repeat;
    margin-top: -100px;
}

.container-icons-bottom {
    background-image: url('/images/bg_2.svg');
    background-position: bottom center;
    background-repeat: no-repeat;
    height: 150px;
    margin-top: -1px;
}


.udao-icons {
    color: white;
}

.container-about {
    background-image: url('/images/bg_3.svg');
    background-position: top center;
    background-repeat: no-repeat;
}

.container-team {
    background-image: url('/images/bg_4.svg');
    background-position: top center;
    background-repeat: no-repeat;
    margin-top: -130px;
}


.container-team-bottom {
    background-image: url('/images/bg_4.svg');
    background-position: bottom center;
    background-repeat: no-repeat;
    height: 150px;
}


@media (max-width: 1500px) {
    .container-team-bottom {
        background-image: none;
        display: none;
    }
}

.container-partners {
    margin-top: -40px !important;
}

@media (max-width: 1500px) {
    .container-partners {
        margin-top: 40px !important;
    }
}

.udao-about {
    color: black;
}

.udao-about-heading {
    color: black;
    font-weight: bold;
    font-size: 1.4rem;
}

.udao-team {
    color: black;
    background-image: url('/images/ellipse.svg');
    background-position: 90% 10%;
    background-repeat: no-repeat;
}

@media (max-width: 850px) {
    .udao-team {
        color: black;
        background-image: url('/images/ellipse.svg');
        background-position: 100% 0%;
        background-repeat: no-repeat;
    }
}

.udao-team-heading {
    color: black;
    font-weight: bold;
    font-size: 1.4rem;
}

.team-name {
    font-weight: bold;
}

@media (max-width: 850px) {
    .container-komfort {
        background-image: url('/images/bg_1_small.svg');
        background-size: cover;
    }

    .container-icons {
        background-color: #5D5493;
        margin-top: -2px;
    }


}

@media (max-width: 980px) {
    .container-about {
        background-image: url('/images/bg_3_small.svg');
        background-size: cover;
    }

    .container-team {
        background-color: #F6F6F9;
        margin-top: 0px;
    }
}

.package-title {
    font-weight: bold;
    font-size: 24px;
}


.cart-item {
    background-color: #F6F6F9;
    border-radius: 30px;
}

.cart-item-extended {
    background-color: #F6F6F9;
    border-radius: 30px;
    box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.07);
}

.cart-item-extended-extension {
    background-color: #F6F6F9;
    border-radius: 30px;
}

.cart-item-extended p {
    padding: 0;
    margin: 0;
}

.cart-item p {
    padding: 0;
    margin: 0;
}

.cart-item-extended .variant-name {
    font-weight: bold;
    margin-top: 15px;
}

.cart-item-extended .insurance-info {
    font-weight: normal;
    font-size: 12px;
    text-align: right;
}

.cart-item-extended .insurance-date {
    font-weight: bold;
    font-size: 16px;
}

.cart-item-extended .insurance-date-info {
    font-weight: normal;
    font-size: 10px;
}

.cart-item .variant-name {
    font-weight: bold;
    margin-top: 15px;
}

.cart-item .insurance-info {
    font-weight: normal;
    font-size: 12px;
    text-align: right;
}

.cart-item .insurance-date {
    font-weight: bold;
    font-size: 16px;
}

.cart-item .insurance-date-info {
    font-weight: normal;
    font-size: 10px;
}

.cart-item .package-name {

}

.cart-item-extended-extension .active {
    font-size: 14px;
    color: black;
    list-style-image: url('/images/vector.svg');
    list-style-type: none;
}

.cart-item-extended-extension .insurance-quotas p {
    margin: 0;
    padding: 0;
    font-size: 12px;
}

.cart-item-extended .insurance-quotas p {
    margin: 0;
    padding: 0;
    font-size: 12px;
}

.cart-item-extended-extension .insurance-quotas .quota {
    font-weight: bolder;
}

.cart-item-extended .insurance-quotas .quota {
    font-weight: bolder;
}

.price {
    text-align: right;
    font-size: 18px;
    font-weight: bold;
    color: #ff7b01;
}

.price .black {
    color: black;
    font-size: 12px;
}

.price .small {
    font-size: 10px;
}

.close-icon {
    cursor: pointer;
}

.cart-header {
    font-size: 24px;
    font-weight: bold;
}

.cart-subheader {
    font-size: 17px;
    font-weight: bold;
}

.person-form {
    background-color: #F6F6F9;
    border-radius: 30px;
}

.person-form input {
    border-radius: 1rem;
    background-color: white;
    border: none;
}

.person-form select {
    border-radius: 1rem;
    background-color: white;
    border: none;
}

.person-form select {
    border-radius: 1rem;
    background-color: white;
    border: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='orange' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
}

.options-header {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}

.additional-package {
    background-color: white;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    cursor: pointer;
}

.additional-package-selected {
    background-color: white;
    border-radius: 30px;
    border: 1px solid #ff7b01;
    cursor: pointer;
}

.additional-package h5 {
    font-size: 14px;
    font-weight: bold;
}

.additional-package p {
    font-size: 10px;
    margin: 0;
}

.additional-package-selected h5 {
    font-size: 14px;
    font-weight: bold;
}

.additional-package-selected p {
    font-size: 10px;
    margin: 0;
}

iframe {
    width: 1px;
    min-width: 100%;
}

.person-form label {
    margin-bottom: 0;
    margin-left: 15px;
    font-size: 12px;
}

.agreements {
    background-color: #F6F6F9;
    border-radius: 30px;
    font-size: 12px;
}

.agreements .form-check-label a {
    color: black;
    text-decoration: none;
    font-weight: bold;
}

.agreements .form-check-input {
    width: 15px;
    height: 15px;
    border: 1px solid #ff7b01;
}

.person-form .person-form-read h5 {
    font-size: 14px;
}

.person-form .person-form-read p {
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.pretend-link {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.payment-form {
    background-color: #F6F6F9;
    border-radius: 30px;
}

.payment_box {
    cursor: pointer;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.selected_payment_box {
    cursor: pointer;
    border-radius: 30px;
    border: 1px solid #ff7b01;
}

.payment_name {
    font-size: 14px;
    font-weight: bold;
}

.payment_description {
    font-size: 11px;
    margin: 0;
    padding: 0
}

.payment-button-box .info {
    font-size: 12px;
    font-weight: bold;
}

.discount-header {
    color: black;
    font-weight: normal;
    font-size: 12px;
}

.discount-price {
    text-align: right;
    font-size: 16px;
    font-weight: bold;
    color: #ff7b01;
}

.payment-form input {
    border-radius: 1rem;
    background-color: white;
    border: none;
}

.discount-code {
    font-weight: bold;
    font-size: 16px;
}

.contest-form label {
    text-align: right;
    font-weight: bold;
    font-size: 13px;
}

.contest-form input {
    border-radius: 1rem;
    background-color: white;
    border: none;
}

.contest-form select {
    border-radius: 1rem;
    background-color: white;
    border: none;
}

.contest-form select {
    border-radius: 1rem;
    background-color: white;
    border: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='orange' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
}

.form-control {
    background-color: white;
    border-radius: 30px;
}

.b-form-datepicker label {
    font-size: 11px !important;
}

.b-calendar-grid-weekdays .col {
    padding-right: 0;
    padding-left: 0;
}

.b-calendar-grid-weekdays .text-truncate {
    font-size: 11px;
}

.b-calendar-grid .no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.b-calendar-inner .form-control {
    background-color: white;
    border-radius: .25rem;
}

.b-calendar-inner .form-control:focus {
    border: 1px solid #ced4da;
    box-shadow: none;
}

.b-calendar-grid .font-weight-bold {
    font-weight: 700 !important;
    color: #343a40 !important;
}

.b-calendar-header {
    display: none;
}

.b-form-datepicker .dropdown-menu {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.footer_phone {
    margin-bottom: 10px;
}

.footer_mail {
    color: white;
    text-decoration: none;
}

.footer_mail:hover {
    color: white;
    text-decoration: none;
}

.footer-text {
    color: white;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
}

.container-partners h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
}

.footer-nav-link {
    font-weight: 700;
    font-size: 16px;
}

.footer-nav-link:hover {
    font-weight: 600;
    font-size: 16px;
}

.contest-paragraph {
    text-align: left;
    font-weight: 700;
    font-size: 16px;
}

.ul-pad {
    padding-left: 0 !important;
}

.btn-primary:disabled, .btn-primary.disabled {
    color: #000;
    background-color: #f6f6f9;
    border-color: #363636;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    border-radius: 0 1rem 1rem 0;
    background-color: white;
    border: none;
}

.input-group-text:disabled {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    border-radius: 0 1rem 1rem 0;
    border: none;
    background-color: #e9ecef;
    opacity: 1;
}

.for-partners-p {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}

.for-partners-h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
}
